<i18n locale="en" lang="yaml">
btn.delete : "ลบรูปภาพ"
error.invalid_type : "อนุญาตเฉพาะไฟล์ที่เป็นรูปภาพเท่านั้น"
error.max_file_size : "ขนาดของรูปภาพไม่ควรเกิน {size}"
error.upload_error : "พบปัญหาขณะ Upload รูปภาพ : {cause}"
</i18n>
<i18n locale="th" lang="yaml" >
btn.delete : "ลบรูปภาพ"
error.invalid_type : "อนุญาตเฉพาะไฟล์ที่เป็นรูปภาพเท่านั้น"
error.max_file_size : "ขนาดของรูปภาพไม่ควรเกิน {size}"
error.upload_error : "พบปัญหาขณะ Upload รูปภาพ : {cause}"
</i18n>

<template>
	<a-spin :spinning="!hideLoading && isLoading" :class="containerCssClasses">
		<Upload
			accept="image/*"
			list-type="picture-card"
			:class="cssClasses"
			:show-upload-list="false"
			:before-upload="beforeUpload">
			<ImageLoader
				:src="displayImage"
				:border-type="borderType"
				:size="size">
				<slot />
			</ImageLoader>
		</Upload>
		<a-button
			v-if="!hideDelete"
			v-show="isEnableDelete"
			class="image-uploader-delete"
			type="link"
			size="small"
			:title="$t('btn.delete')"
			@click="handleDelete">
			<a-icon type="delete" theme="filled" />
		</a-button>
	</a-spin>
</template>

<script>
import ImageLoader from '@components/common/ImageLoader.vue'
import {Upload} from "ant-design-vue"
import startsWith from "lodash/startsWith"
import {isStringEmpty} from "@utils/stringUtil.js"
import Compressor from "compressorjs"

export default {
	components : {
		ImageLoader,
		Upload
	} ,
	inheritAttrs: false,
	props : {
		borderType : {
			type : String ,
			default : 'normal'
		} ,
		size : {
			type : String ,
			default : null
		} ,
		hideLoading : {
			type : Boolean,
			default : false
		} ,
		hideDelete : {
			type : Boolean ,
			default : false,
		} ,
		currentImageUrl : {
			type : String,
			default : null
		} ,
		maxWidth : {
			type : Number ,
			default : 0
		} ,
		maxHeight : {
			type : Number ,
			default : 0
		} ,
		// For Now, No need for this prop
		// maxFileSize : {
		// 	type : Number ,
		// 	default : 5*1024*1024,
		// } ,
		messageInvalidType : {
			type : String,
			default : null
		} ,
		messageMaxSize : {
			type : String,
			default : null
		} ,
		messageUploadError : {
			type : String ,
			default : null
		}

	} ,
	data() {
		return {
			isLoading : false ,
			isDeleted : false ,
			contentBase64 : null,
			contentType : null ,
		}
	} ,
	computed : {
		isEnableDelete() {
			return !this.hideDelete && (this.displayImage !== null)
		} ,
		displayImage() {
			if (this.isDeleted)
				return null;
			else if (this.contentBase64)
				return this.contentBase64;
			else
				return this.currentImageUrl;
		} ,
		containerCssClasses() {
			return {
				'image-uploader-container' : true,
				'has-delete' : !this.hideDelete
			}
		} ,
		cssClasses() {
			const classes = ['image-uploader'];
      if (!isStringEmpty(this.size))
        classes.push('image-size-'+this.size);
      return [
        classes ,
        {
          'image-circle' : this.borderType === 'circle' ,
          'image-round' : this.borderType === 'round' ,
        }
      ];
		}
	} ,
	mounted() {
		this.emitChange();
	} ,
	methods : {
		beforeUpload(file) {
			this.isLoading = false;

			// validate
			if (!startsWith(file.type,'image/')) {
				const msg = isStringEmpty(this.messageInvalidType) ? this.$t('error.invalid_type') : this.messageInvalidType;
				this.$emit('error',new Error(msg));
				return false;
			}
			if (this.maxFileSize > 0 && file.size > 10*1024*1024) {
				const msg = isStringEmpty(this.messageMaxSize) ? this.$t('error.max_size',{size : "10MB"}) : this.messageMaxSize;
				this.$emit('error',new Error(msg));
				return false;
			}
			/* eslint-disable no-unused-vars */
			const compressor = new Compressor(file,{
				quality : 0.8 ,
				maxWidth : this.maxWidth ,
				maxHeight : this.maxHeight ,
				success : (result) => {
					const reader = new FileReader();
					reader.addEventListener('load',() => {
						this.contentBase64 = reader.result;
						this.contentType = file.type;
						this.isLoading = false;
						this.isDeleted = false;
						this.emitChange();
					})
					reader.addEventListener('error', (error) => {
						this.uploadError(error)
					})
					reader.readAsDataURL(result)
				} ,
				error(error) {
					this.uploadError(error)
				}
			});
			return false;
		} ,
		uploadError(error) {
				this.contentBase64 = null;
				this.isLoading = false;
				const msg = isStringEmpty(this.messageUploadError) ? this.$t('error.upload_error',{cause : error}) : this.messageUploadError;
				this.$emit('error',new Error(msg));
		} ,
		handleDelete() {
			this.isDeleted = true;
			this.contentBase64 = null;
			this.contentType = null;

			this.emitChange();
		} ,
		emitChange() {
			const data = {
				action : 'none'
			};
			if (this.isDeleted) {
				data.action = 'delete';
			} else if (this.contentBase64 !== null) {
				data.action = 'upload';
				data.content = this.contentBase64;
				data.content_type = this.contentType;
			}
			this.$emit('change',data);
		}
	} ,
}
</script>
