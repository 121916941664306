<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
part.section.general : "ข้อมูลทั่วไป"
part.section.price : "ข้อมูลราคา"
part.section.warranty : "ข้อมูลการรับประกัน"
part.section.others : "อื่น ๆ"
part.field.image : "รูปภาพอะไหล่"
part.field.sku.help : "หมายเลข SKU ของอะไหล่ ห้ามซ้ำ"
part.field.part_type.help : "ประเภทของอะไหล่ ตามการใช้งานของอะไหล่แต่ละส่วน"
part.field.part_type.placeholder : "เลือกประเภทของอะไหล่"
part.field.name.help : "ชื่ออะไหล่ เป็นภาษาไทย"
part.field.name_en.help : "ชื่ออะไหล่ เป็นภาษาอังกฤษ"
part.field.has_serial_no.help : "อะไหล่นี้จะต้องระบุหมายเลข Serial No ด้วยทุกครั้ง เพื่อใช้ในการตรวจสอบ"
part.field.strict_use.help : "อะไหล่นี้ใช้งานได้เฉพาะ Drone ที่ระบุไว้เท่านั้น"
part.field.cost_per_unit.help : "ราคาขายให้ตัวแทนจำหน่าย ไม่รวม VAT"
part.field.price_per_unit.help : "ราคาขายของสินค้าต่อหน่วย (ลูกค้าซื้อ) ไม่รวม VAT"
part.field.warranty_type.help : "รูปแบบการรับประกัน ที่จะดูเพิ่มเติมจากระยะเวลาซื้อ (ปกติ คือ ดูจากเวลารับประกันอย่างเดียว)"
part.field.warranty_duration.help : "นับตั้งแต่วันที่ที่ลูกค้าซื้อ ถ้าไม่ระบุคือไม่มีการรับประกัน"
part.field.data_date.help : "ข้อมูลเป็นข้อมูลของวันที่ ? (ใช้อ้างอิงกับระบบอื่น)"
part.field.remark.placeholder : "หมายเหตุเพิ่มเติม เกี่ยวกับ อะไหล่ เช่น วิธีการติดตั้ง หรือ การใช้งานอื่น ๆ"

part.field.has_serial_no : "ต้องการเลข Serial No. ?"
part.field.strict_use : "ต้องใช้งานตรงกับ Drone ที่ระบุ"
</i18n>

<template>
	<a-form
		layout="horizontal"
		:label-col="labelCol"
		:wrapper-col="wrapperCol"
		:form="formObj"
		@submit.prevent="handleSubmit">
		<div class="mylayout-left-right responsive">
			<div class="mylayout-left">
				<a-card :bordered="false" class="text-center">
					<ImageUploader
						:current-image-url="currentPartImageUrl"
						border-type="round"
						size="large"
						:max-width="512"
						:max-height="512"
						@error="handleFileUploadError"
						@change="hadleFileUploadChange">
						<a-icon type="deployment-unit" />
					</ImageUploader>
					<div class="text-muted">{{$t('part.field.image')}}</div>
				</a-card>
			</div>

			<div class="mylayout-right">
				<a-card :bordered="false">

					<div class="myform-section-title">
						{{$t('part.section.general')}}
					</div>
					<a-form-item :label="$t('part.field.sku')"
						:extra="$t('part.field.sku.help')">
						<a-input
							v-decorator="[
								'sku' ,
								{ rules: [
									{ required: true ,whitespace: true ,message: $tt('validate.required','part.field.sku') },
								] },
							]"
							type="text"
							:placeholder="$t('part.field.sku')"/>
					</a-form-item>
					<a-form-item :label="$t('part.field.part_type')"
						:extra="$t('part.field.part_type.help')">
						<a-select
							v-decorator="[
								'part_type' ,
								{ rules: [
									{ required: true ,whitespace: true ,message: $tt('validate.required','part.field.part_type') },
								] },
							]"
							:get-popup-container="getPopupContainer"
							:placeholder="$t('part.field.part_type.placeholder')">
							<a-select-option value="electronic">{{$tenum('part_type','electronic')}}</a-select-option>
							<a-select-option value="mechanic">{{$tenum('part_type','mechanic')}}</a-select-option>
							<a-select-option value="material">{{$tenum('part_type','material')}}</a-select-option>
							<a-select-option value="sensor">{{$tenum('part_type','sensor')}}</a-select-option>
							<a-select-option value="device">{{$tenum('part_type','device')}}</a-select-option>
							<a-select-option value="battery">{{$tenum('part_type','battery')}}</a-select-option>
							<a-select-option value="others">{{$tenum('part_type','others')}}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item :label="$t('part.field.name')"
						:extra="$t('part.field.name.help')">
						<a-input
							v-decorator="[
								'name' ,
								{ rules: [
									{ required: true ,whitespace: true ,message: $tt('validate.required','part.field.name') },
								] },
							]"
							type="text"
							:placeholder="$t('part.field.name')"/>
					</a-form-item>

					<a-form-item :label="$t('part.field.name_en')"
						:extra="$t('part.field.name_en.help')">
						<a-input
							v-decorator="['name_en']"
							type="text"
							:placeholder="$t('part.field.name_en')"/>
					</a-form-item>
					<a-form-item :extra="$t('part.field.has_serial_no.help')"
						v-bind="wrapperOnlyLayout">
						<a-checkbox
							v-decorator="['has_serial_no',{ valuePropName: 'checked' }]">
						 {{$t('part.field.has_serial_no')}}
						</a-checkbox>
					</a-form-item>

					<a-form-item :extra="$t('part.field.strict_use.help')"
						v-bind="wrapperOnlyLayout">
						<a-checkbox
							v-decorator="['strict_use',{ valuePropName: 'checked' }]">
						 {{$t('part.field.strict_use')}}
						</a-checkbox>
					</a-form-item>
					<hr />
					<div class="myform-section-title">
						{{$t('part.section.warranty')}}
					</div>
					<a-form-item :label="$t('part.field.warranty_duration')"
						:extra="$t('part.field.warranty_duration.help')">
						<a-input-number
							v-decorator="['warranty_duration']"
							:min="0" :step="1"
							:precision="0"
							class="myinput-number">
						</a-input-number>
						<span class="ant-form-text">
							{{$t('common.day')}}
						</span>
					</a-form-item>

					<a-form-item :label="$t('part.field.warranty_type')"
						:extra="$t('part.field.warranty_type.help')">
						<a-radio-group
							v-decorator="[
								'warranty_type' ,
								{
									rules: [
									{ required: true ,whitespace: true ,message: $tt('validate.required','part.field.warranty_type') },
									] ,
									'initialValue': 'none',
								},
							]"
							@change="handleWarrantyTypeChange">
							<a-radio value="none">{{$tenum('warranty_type','none')}}</a-radio>
							<a-radio value="flight">{{$tenum('warranty_type','flight')}}</a-radio>
							<a-radio value="charge">{{$tenum('warranty_type','charge')}}</a-radio>
						</a-radio-group>
					</a-form-item>


					<a-form-item v-show="showWarrantyValue" :label="warrantyValueLabel">
						<a-input-number
							v-decorator="['warranty_value']"
							:min="0" :step="1"
							:precision="0"
							class="myinput-number">
						</a-input-number>
						<span class="ant-form-text">
							{{warrantyValueUnit}}
						</span>
					</a-form-item>

					<hr />
					<div class="myform-section-title">
						{{$t('part.section.price')}}
					</div>

					<a-form-item :label="$t('part.field.cost_per_unit')"
						:extra="$t('part.field.cost_per_unit.help')">
						<a-input-number
								v-decorator="[
								'cost_per_unit' ,
								{ rules: [
									{ required: true, message: $tt('validate.required', 'part.field.cost_per_unit_required') },
									{ pattern: /^(\d{1,9}|\d{0,9}\.\d{1,2})$/, message: $t('part.field.invalid.price.pattern') },
								] },
							]"
							:min="0" :step="0.01"
							:precision="2"
							class="myinput-number">
						</a-input-number>
						<span class="ant-form-text">
							{{$t('common.currency.unit')}}
						</span>
					</a-form-item>
					<a-form-item :label="$t('part.field.price_per_unit')"
						:extra="$t('part.field.price_per_unit.help')">
						<a-input-number
							v-decorator="[
								'price_per_unit' ,
								{ rules: [
									{ required: true, message: $tt('validate.required', 'part.field.price_per_unit_required') },
									{ pattern: /^(\d{1,9}|\d{0,9}\.\d{1,2})$/, message: $t('part.field.invalid.price.pattern') },
								] },
							]"
							:min="0" :step="0.01"
							:precision="2"
							class="myinput-number"
							length="30">
						</a-input-number>
						<span class="ant-form-text">
							{{$t('common.currency.unit')}}
						</span>
					</a-form-item>
					<a-form-item :label="$t('part.field.repair.duration')">
						<a-input-number
							v-decorator="[
								'remark' ,
								{ rules: [
									{ required: true, message: $tt('validate.required','part.field.repair.duration') },
									{ pattern: /^[0-9]*$/, message: $t('part.field.invalid.repair_duration.pattern') },
								] },
							]"
							:min="0" :step="5"
							class="myinput-number"
							length="30">
						</a-input-number>
						<span class="ant-form-text">
							{{$t('common.time.minute')}}
						</span>
					</a-form-item>

					<hr />
					<div class="myform-section-title">
						{{$t('part.section.others')}}
					</div>
					<a-form-item :label="$t('part.field.data_date')"
						:extra="$t('part.field.data_date.help')">
						<MyDatePicker
							v-decorator="['data_date']"
							format="DD MMMM YYYY"/>
					</a-form-item>

					<!-- <a-form-item :label="$t('part.field.remark')">
						<a-textarea
							v-decorator="['remark']"
							:auto-size="{ minRows: 2,maxRows: 5 }"
							:placeholder="$t('part.field.remark.placeholder')">
						</a-textarea>
					</a-form-item> -->





					<a-form-item v-bind="wrapperOnlyLayout">
						<slot name="submitSlot" :formLoading="loading">
							<a-button
								:loading="loading"
								type="info"
								html-type="submit"
								class="btn-submit"
								size="large">
								{{$t('common.save')}}
							</a-button>
						</slot>
					</a-form-item>
				</a-card>
			</div>
		</div>
	</a-form>

</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import ImageUploader from '@components/input/ImageUploader.vue'
import {updateFieldsValue} from "@utils/formUtil.js"
import {Select,InputNumber,Checkbox,Radio} from "ant-design-vue"
import MyDatePicker from '@components/input/MyDatePicker.vue'
import ApiError from '@utils/errors/ApiError'
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		ImageUploader,
		"a-select" : Select, "a-select-option" : Select.Option,
		"a-input-number" : InputNumber,
		"a-checkbox" : Checkbox,
		"a-radio" : Radio,
		"a-radio-group" : Radio.Group,
		MyDatePicker,
	} ,
	mixins: [HasAntdFormMixin,PopupMixin],
	props : {
		mode : {
			type : String ,
			default : 'update'
		} ,
		loading : {
			type : Boolean ,
			default : false,
		}
	} ,
	data() {
		return {
			currentPartImageUrl : null ,
			imageUploadData : {} ,
			showWarrantyValue : false,
			warrantyValueLabel : "",
			warrantyValueUnit : "",
		}
	} ,
	methods : {
		updateWarrantyType(value) {
			if (value === 'flight') {
				this.warrantyValueLabel = this.$t('part.field.warranty_value.flight');
				this.warrantyValueUnit = this.$t('common.hour');
				this.showWarrantyValue = true;
			} else if (value === 'charge') {
				this.warrantyValueLabel = this.$t('part.field.warranty_value.charge');
				this.warrantyValueUnit = this.$t('common.charge.unit');
				this.showWarrantyValue = true;
			} else {
				this.showWarrantyValue = false;
				if (value != 'none')
					this.formObj.setFieldsValue({'warranty_type' : 'none'})
			}
		} ,
		handleWarrantyTypeChange(e) {
			this.updateWarrantyType(e.target.value);
			this.formObj.setFieldsValue({'warranty_value' : null})
		} ,
		handleFileUploadError(error) {
			this.$message.error(ApiError.getDisplayErrorMessage(this,error));
		} ,
		hadleFileUploadChange(value) {
			this.imageUploadData = value;
		} ,
		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				if (!err) {
					const formData = {
						...values ,
						'image_file_base64' : this.imageUploadData ,
					};
					this.$emit('submit',formData)
				} else {
					this.displayValidateErrorMessage()
				}
			})
		} ,
		updatePartForm(part) {
			this.currentPartImageUrl = part.image_url
			updateFieldsValue(this.formObj,part)
			this.updateWarrantyType(part.warranty_type)
		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		}
	}
}
</script>
