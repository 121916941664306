<i18n locale="th" lang="yaml" >
page.title : "เพิ่มข้อมูลอะไหล่"
page.description : "เพิ่มข้อมูลอะไหล่ที่ใช้งานกับ Drone ในระบบ"
create_part.success : "ทำการเพิ่มอะไหล่ \"SKU : {name}\" ในระบบเรียบร้อย"
create_part.submit.label : "เพิ่มอะไหล่"

create_part.confirm.title : "ยืนยันเพิ่มอะไหล่"
create_part.confirm.message : "คุณต้องการเพิ่มอะไหล่นี้เข้าระบบ ?"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>
		<PartForm
			ref="formRef"
			:loading="loading"
			mode="create"
			@submit="handleSubmit">
			<template v-slot:submitSlot="{ formLoading }">
				<a-button
					:loading="formLoading"
					type="info"
					html-type="submit"
					class="btn-submit"
					icon="edit"
					size="large">
					{{$t('create_part.submit.label')}}
				</a-button>
			</template>
		</PartForm>
	</div>
</template>

<script>
import PartForm from '@components/part/PartForm.vue'
import PageMixin from '@mixins/PageMixin.vue'
import axios from "axios"
import {mapActions} from "vuex"

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components : {
		PartForm
	} ,
	mixins: [PageMixin],
	data() {
		return {
			loading : false,
		}
	} ,
	methods : {
		...mapActions('drone',['fetchParts']),
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('create_part.confirm.title') ,
				content : this.$t('create_part.confirm.message') ,
				maskClosable : true,
				onOk: () => {
					this.loading = true
					axios.post("/api/parts/create",formData).then((response) => {
						const newPart = response.data.data.part
						this.$router.push({
							name : 'part/view',
							params : {id : newPart.id}
						});

						this.fetchParts()
						this.$message.success(this.$t('create_part.success',{"name" : newPart.name}))
						this.$open({name:'part/view',params:{id:newPart.id}})
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=>{
						this.loading = false
					});
				}
			});
		}
	}
}
</script>
